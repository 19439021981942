.ServicePage {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem;

  .ServicePageMap {
    div {
      border-radius: 1rem;
    }
  }
}
