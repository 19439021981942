body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
  width: 100%;
  display: flex;
  // background: url(https://static.wixstatic.com/media/79d5cf_54ef798f848d4c80a25533ac2861a084~mv2.png/v1/fill/w_582,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/CCD%20NETWORK_LANDSCAPE_COLOUR.png)
  //   no-repeat top right;
  background-size: 300px !important;
}
